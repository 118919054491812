import {
  DocumentInterface,
  DocumentTypeInterface,
  FullDocumentInterface,
  FullDocumentTypeInterface,
} from '@/typings/model';
import { Nullable, request, ResponseBody } from '@tager/admin-services';

export interface SuccessData {
  success: boolean;
}

export interface CountData {
  count: number;
}

export function getDocumentTypeCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: '/admin/documents/types/count' });
}

export function getDocumentTypeList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<DocumentTypeInterface>>> {
  return request.get({ path: '/admin/documents/types', params });
}

export function getDocumentType(
  documentTypeId: string | number
): Promise<ResponseBody<FullDocumentTypeInterface>> {
  return request.get({ path: `/admin/documents/types/${documentTypeId}` });
}

export interface DocumentTypeUpdatePayload {
  fileId: Nullable<string>;
  filenameTemplate: Nullable<string>;
  numberTemplate: Nullable<string>;
}

export function updateDocumentType(
  documentTypeId: number | string,
  payload: DocumentTypeUpdatePayload
): Promise<ResponseBody<FullDocumentTypeInterface>> {
  return request.put({
    path: `/admin/documents/types/${documentTypeId}`,
    body: payload,
  });
}

export function getDocumentCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: '/admin/documents/count' });
}

export function getDocumentList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<DocumentInterface>>> {
  return request.get({ path: '/admin/documents', params });
}

export function getDocument(
  documentId: string | number
): Promise<ResponseBody<FullDocumentInterface>> {
  return request.get({ path: `/admin/documents/${documentId}` });
}
