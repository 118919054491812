import { ROUTE_PATHS } from '@/constants/paths';
import { compile } from 'path-to-regexp';

export function getDocumentTypeListUrl() {
  return ROUTE_PATHS.DOCUMENT_TYPE_LIST;
}

export function getDocumentTypeFormUrl(params: { typeId: string | number }) {
  return compile(ROUTE_PATHS.DOCUMENT_TYPE_FORM)(params);
}

export function getDocumentListUrl() {
  return ROUTE_PATHS.DOCUMENT_LIST;
}

export function getDocumentViewUrl(params: { documentId: string | number }) {
  return compile(ROUTE_PATHS.DOCUMENT_VIEW)(params);
}
