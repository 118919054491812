
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGridItem, NavigationGrid } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { getLinks } from "@/constants/links";
import { getDocumentCount, getDocumentTypeCount } from "@/services/requests";

export default defineComponent({
  name: "HomePage",
  components: { NavigationGrid },
  setup() {
    const links = computed(() => getLinks());

    const [
      fetchDocumentCount,
      { data: documentCountData, status: documentCountDataStatus },
    ] = useResource({
      fetchResource: getDocumentCount,
      resourceName: "Количество документов",
      initialValue: null,
    });

    const [
      fetchDocumentTypeCount,
      { data: documentTypeCountData, status: documentTypeCountDataStatus },
    ] = useResource({
      fetchResource: getDocumentTypeCount,
      resourceName: "Количество типов документов",
      initialValue: null,
    });

    onMounted(() => {
      fetchDocumentCount();
      fetchDocumentTypeCount();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.DOCUMENT_TYPE_LIST.text,
          url: links.value.DOCUMENT_TYPE_LIST.url,
          total: {
            status: documentTypeCountDataStatus.value,
            value: documentTypeCountData.value?.count ?? 0,
          },
        },
        {
          name: links.value.DOCUMENT_LIST.text,
          url: links.value.DOCUMENT_LIST.url,
          total: {
            status: documentCountDataStatus.value,
            value: documentCountData.value?.count ?? 0,
          },
        },
        {
          name: "Администраторы",
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
