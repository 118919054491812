
import { defineComponent } from "vue";

import { ColumnDefinition, useDataTable, DataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getDocumentList } from "@/services/requests";
import { DocumentInterface } from "@/typings/model";
import { getDocumentTypeFormUrl } from "@/utils/paths";

const COLUMN_DEFS: Array<ColumnDefinition<DocumentInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "52px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "52px", textAlign: "center" },
  },
  {
    id: 2,
    name: "Тип",
    field: "documentTypeName",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.documentTypeName,
        url: getDocumentTypeFormUrl({ typeId: row.documentTypeId }),
      },
    }),
  },
  {
    id: 3,
    name: "Номер",
    field: "numberString",
    style: { width: "130px", textAlign: "center" },
    headStyle: { width: "130px", textAlign: "center" },
  },
  {
    id: 4,
    name: "Имя файла",
    field: "filename",
  },
  {
    id: 6,
    name: "DOC Файл",
    field: "docFile",
    type: "file",
    style: { width: "150px", textAlign: "center" },
    headStyle: { width: "150px", textAlign: "center" },
  },
  {
    id: 7,
    name: "PDF Файл",
    field: "pdfFile",
    type: "file",
    style: { width: "150px", textAlign: "center" },
    headStyle: { width: "150px", textAlign: "center" },
  },
];
export default defineComponent({
  name: "DocumentList",
  components: { Page, DataTable },
  setup() {
    const {
      isLoading: isDocumentListDataLoading,
      rowData: documentListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<DocumentInterface>({
      fetchEntityList: (params) =>
        getDocumentList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список документов",
    });

    function isBusy(): boolean {
      return isDocumentListDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: documentListData,
      isRowDataLoading: isDocumentListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
    };
  },
});
