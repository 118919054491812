import { Nullable } from '@tager/admin-services';

import { FullDocumentTypeInterface } from '@/typings/model';
import { DocumentTypeUpdatePayload } from '@/services/requests';
import { SingleFileInputValueType } from '@tager/admin-ui';
import { createFileInputValue } from '@/utils/common';

export interface FormValues {
  file: Nullable<SingleFileInputValueType>;
  filenameTemplate: Nullable<string>;
  numberTemplate: Nullable<string>;
}

export function convertDocumentTypeToFormValues(
  documentType: Nullable<FullDocumentTypeInterface>
): FormValues {
  return {
    file: documentType?.file ? createFileInputValue(documentType.file) : null,
    filenameTemplate: documentType?.filenameTemplate ?? '',
    numberTemplate: documentType?.numberTemplate ?? '',
  };
}

export function convertFormValuesToDocumentTypeUpdatePayload(
  values: FormValues
): DocumentTypeUpdatePayload {
  return {
    fileId: values.file?.file.id ?? null,
    filenameTemplate: values.filenameTemplate,
    numberTemplate: values.numberTemplate,
  };
}
