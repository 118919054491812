import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";

import { getDocumentListUrl, getDocumentTypeListUrl } from "@/utils/paths";

type LinkKey =
  | "HOME"
  | "DOCUMENT_LIST"
  | "DOCUMENT_TYPE_LIST"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Главная",
  },
  DOCUMENT_TYPE_LIST: {
    url: getDocumentTypeListUrl(),
    text: "Типы документов",
  },
  DOCUMENT_LIST: {
    url: getDocumentListUrl(),
    text: "Документы",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
});
